export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowerCaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string' || value instanceof String;
}

export function translationFromAttribute(string: string): string {
  return capitalizeFirstLetter(string).replace(/_/g, ' ');
}
