import useAuthStore from '~/stores/auth';

import type { RouteRecordRaw } from 'vue-router';

const authRoutes: RouteRecordRaw[] = [
  {
    name: 'auth.login',
    path: '/auth/login',
    component: async () => import('~/views/auth/Login.vue'),
    meta: {
      middleware: {
        before: ['guest'],
      },
    },
  },
  {
    name: 'auth.forgotPassword',
    path: '/auth/forgot-password',
    component: async () => import('~/views/auth/ForgotPassword.vue'),
    meta: {
      middleware: {
        before: ['guest'],
      },
    },
  },
  {
    name: 'auth.resetPassword',
    path: '/auth/reset-password',
    component: async () => import('~/views/auth/ResetPassword.vue'),
    meta: {
      middleware: {
        before: ['guest'],
      },
    },
  },
  {
    name: 'auth.activate',
    path: '/auth/activate',
    component: async () => import('~/views/auth/Activate.vue'),
    meta: {
      middleware: {
        before: ['guest'],
      },
    },
  },
  {
    name: 'auth.2fa',
    path: '/auth/2fa',
    component: async () => import('~/views/auth/TwoFactorAuthentication.vue'),
    meta: {
      middleware: {
        before: ['guest'],
      },
    },
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
    beforeEnter: () => {
      const authStore = useAuthStore();

      if (authStore.twoFactorAuthenticationIsEnabled && authStore.hasPendingLogin) {
        return true;
      }

      return {
        name: 'auth.login',
      };
    },
  },
];

export default authRoutes;
