<script lang="ts" setup>
  import { computed } from 'vue';
  import NavigationGroup from './NavigationGroup.vue';
  import NavigationItem from './NavigationItem.vue';

  import type { NavigationMenu, NavigationMenuItem, NavigationMenuGroup } from '~/typings/types';

  const props = defineProps<{
    items: NavigationMenu;
    collapsed?: boolean;
  }>();

  const navigationItems = computed(() => {
    return props.items.filter(item => item.visible === undefined || item.visible);
  });
</script>

<template>
  <nav
    :class="{ 'px-6': !collapsed }"
    class="flex-1"
    data-testid="main-nav"
  >
    <template
      v-for="item in navigationItems"
      :key="item.name"
    >
      <NavigationGroup
        v-if="Object.hasOwn(item, 'items')"
        :item="item as NavigationMenuGroup"
        :collapsed="collapsed"
      />

      <NavigationItem
        v-else
        :item="item as NavigationMenuItem"
        :collapsed="collapsed"
      />
    </template>
  </nav>
</template>
