import Cookies from 'js-cookie';
import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { setUser } from '@sentry/vue';
import { isApolloError } from '@apollo/client/core';
import { useLoginMutation, useLogoutMutation, useMeQuery, useRefreshTokenMutation } from '~/graphql/graphql';
import { useQueryPromise } from '~/services/graphql';
import { getRouter } from '~/lib/Router';
import useToast from '~/stores/toast';

import type { CookieAttributes } from 'js-cookie';
import type { ApolloError, FetchResult } from '@apollo/client/core';
import type {
  AuthPayload,
  LoginInput,
  User,
  RoleName,
  PermissionName,
  LoginMutation,
  OtpResponse,
} from '~/graphql/graphql';

type LoginResponse = NonNullable<FetchResult<LoginMutation, Record<string, unknown>, Record<string, unknown>>>['data'];

export type AuthEvents = 'beforeLogout' | 'afterLogout' | 'beforeLogin' | 'afterLogin';
export type AuthEventListener = () => unknown;

const cookieOptions: CookieAttributes = {
  secure: true,
  sameSite: 'strict',
  expires: 7,
};

const useAuthStore = defineStore('auth', () => {
  const registeredEventListeners = new Map<AuthEvents, Set<AuthEventListener>>();
  const loading = ref(false);
  const accessToken = ref<string>();
  const refreshToken = ref<string>();
  const user = ref<User>();
  const hasPendingLogin = ref(false);
  const has2fa = ref(false);
  const otpResponse = ref<OtpResponse | null>(null);
  const loginResponse = ref<LoginResponse | null>(null);
  const twoFactorAuthenticationIsEnabled = ref(false);

  const authenticated = computed(() => {
    if (twoFactorAuthenticationIsEnabled.value) {
      return !!(accessToken.value && refreshToken.value && has2fa.value);
    }

    return !!(accessToken.value && refreshToken.value);
  });

  watch(
    () => user.value,
    value => setUser(value ? { id: value.id, email: value.email } : null),
    { immediate: true },
  );

  function addEventListener(event: AuthEvents, listener: AuthEventListener): void {
    if (!registeredEventListeners.has(event)) {
      registeredEventListeners.set(event, new Set([listener]));
    } else {
      registeredEventListeners.get(event)?.add(listener);
    }
  }

  function removeEventListener(event: AuthEvents, listener: AuthEventListener): void {
    if (registeredEventListeners.has(event)) {
      registeredEventListeners.get(event)?.delete(listener);
    }
  }

  async function callEventListeners(event: AuthEvents): Promise<unknown[]> {
    if (!registeredEventListeners.has(event)) {
      return [];
    }

    const promises = [];

    for (const listener of registeredEventListeners.get(event) ?? new Set()) {
      promises.push(listener());
    }

    return Promise.all(promises);
  }

  async function getUser(): Promise<boolean> {
    try {
      const response = await useQueryPromise(useMeQuery());

      if (!response?.data?.me) {
        return false;
      }

      user.value = response.data.me;
    } catch (error) {
      console.error('GET USER ERROR', error);

      return false;
    }

    return true;
  }

  function clearPendingLogin(): void {
    has2fa.value = false;
    hasPendingLogin.value = false;
    otpResponse.value = null;
    loginResponse.value = null;
    twoFactorAuthenticationIsEnabled.value = false;
  }

  async function login(credentials: LoginInput): Promise<LoginResponse | null> {
    await callEventListeners('beforeLogin');

    try {
      const response = await useLoginMutation().mutate({ input: credentials });
      loginResponse.value = response?.data ?? null;

      if (!response?.data?.login?.access_token || !response?.data?.login?.refresh_token) {
        return null;
      }

      if (response.data.login.otp) {
        twoFactorAuthenticationIsEnabled.value = true;
        otpResponse.value = response.data.login.otp;
      } else {
        clearPendingLogin();
      }

      user.value = response.data.login.user ?? undefined;

      accessToken.value = response.data.login.access_token;
      refreshToken.value = response.data.login.refresh_token;
      hasPendingLogin.value = twoFactorAuthenticationIsEnabled.value;

      await callEventListeners('afterLogin');

      return response.data;
    } catch (error) {
      loginResponse.value = null;
      let header = 'Something went wrong';
      let body = 'Please try again later.';

      if (isApolloError(error as Error)) {
        const apolloError = error as ApolloError;
        const hasIncorrectCredentialsError = apolloError.graphQLErrors.some(gqlError => {
          return gqlError.extensions.reason === 'Incorrect username or password';
        });

        if (hasIncorrectCredentialsError) {
          header = 'Incorrect email or password';
          body = 'Check that you have entered the correct email address and password.';
        }
      }

      useToast('error', { header, body });

      await callEventListeners('afterLogin');

      return null;
    }
  }

  function clearLogin(): void {
    clearPendingLogin();
    accessToken.value = undefined;
    refreshToken.value = undefined;
    user.value = undefined;
  }

  function loginWithAuthPayload(authPayload?: AuthPayload): boolean {
    if (
      !authPayload?.access_token
      || !authPayload?.refresh_token
      || !authPayload?.user
    ) {
      return false;
    }

    accessToken.value = authPayload.access_token;
    refreshToken.value = authPayload.refresh_token;
    user.value = authPayload.user;

    return true;
  }

  async function logout(force = false): Promise<void> {
    await callEventListeners('beforeLogout');

    try {
      if (!force) {
        await useLogoutMutation().mutate();
      }
    } finally {
      clearLogin();

      await getRouter().push({
        name: 'auth.login',
      });
    }

    await callEventListeners('afterLogout');
  }

  async function refresh(): Promise<boolean> {
    try {
      loading.value = true;

      const response = await useRefreshTokenMutation().mutate({
        input: {
          refresh_token: refreshToken.value,
        },
      });

      if (!response?.data?.refreshToken?.access_token || !response?.data?.refreshToken?.refresh_token) {
        loading.value = false;

        return false;
      }

      accessToken.value = response.data.refreshToken.access_token;
      refreshToken.value = response.data.refreshToken.refresh_token;

      await getUser();
      loading.value = false;
    } catch {
      await logout(true);
      loading.value = false;

      return false;
    }

    return true;
  }

  function hasRole(...roles: RoleName[]): boolean {
    return !!user.value?.roles?.some(item => item && roles.includes(item));
  }

  function hasPermission(...permissions: PermissionName[]): boolean {
    return !!user.value?.permissions.some(item => item && permissions.includes(item));
  }

  async function waitForUser(): Promise<void> {
    const check = (resolve: () => void, reject: () => void): void => {
      if (user.value !== undefined) {
        resolve();
      } else if (!authenticated.value) {
        // do nothing
      } else {
        setTimeout(check.bind(this, resolve, reject), 50);
      }
    };

    return new Promise(check);
  }

  async function resolveLogin(): Promise<void> {
    if (!user.value?.roles) {
      await refresh();
      await waitForUser();
    }

    hasPendingLogin.value = false;
  }

  return {
    accessToken,
    refreshToken,
    has2fa,
    otpResponse,
    user,
    authenticated,
    hasPendingLogin,
    loading,
    loginResponse,
    twoFactorAuthenticationIsEnabled,

    login,
    resolveLogin,
    clearLogin,
    logout,
    refresh,
    getUser,
    loginWithAuthPayload,
    hasRole,
    hasPermission,
    waitForUser,

    addEventListener,
    removeEventListener,
  };
}, {
  persist: {
    storage: {
      getItem: (key: string) => JSON.parse(Cookies.get(key) ?? '{}'),
      setItem: (key: string, value: string) => Cookies.set(key, JSON.stringify(value), cookieOptions),
    },
    paths: [
      'accessToken',
      'refreshToken',
      'has2fa',
      'twoFactorAuthenticationIsEnabled',
      'hasPendingLogin',
      'otpResponse',
    ],
    afterRestore: context => {
      if (context.store.$state.accessToken && context.store.$state.refreshToken
        && (!context.store.twoFactorAuthenticationIsEnabled || context.store.$state.has2fa)
      ) {
        context.store.getUser();
      }
    },
  },
});

export default useAuthStore;
