import useAuthStore from '~/stores/auth';
import { normalizeRoute } from '~/lib/Router';

import type { RouteLocationNormalized } from 'vue-router';
import type { Destination, Middleware, Next } from '~/typings/types';

export default class Authorized implements Middleware {
  // eslint-disable-next-line class-methods-use-this
  public async handle(to: RouteLocationNormalized, next: Next): Promise<Destination> {
    const auth = useAuthStore();

    await auth.waitForUser();

    // Check if users profile is completed
    if (!auth.user?.completed && !auth.user?.completed_at) {
      return normalizeRoute('profile-wizard');
    }

    if (
      (to.meta.can !== undefined && !auth.hasPermission(...to.meta.can))
            || (to.meta.is !== undefined && !auth.hasRole(...to.meta.is))
    ) {
      return normalizeRoute('unauthorized');
    }

    return next(to);
  }
}
