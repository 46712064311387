export default {
    'No open actions have been completed yet': 'Er zijn nog geen openstaande acties afgerond.',
    'This question type is not yet supported': 'Dit vraagtype wordt nog niet ondersteund.',
    'Cannot edit signature': 'Kan handtekening niet aanpassen',
    'The signature must first be removed to add a new one': 'De handtekening moet eerst verwijderd worden om een nieuwe toe te voegen.',
    'Complete answers': 'Antwoorden invullen',
    'Answer all mandatory questions': 'Beantwoord alle verplichte vragen.',
    'Answer at least one question': 'Beantwoord ten minste één vraag.',
    'Save checklist as draft': 'Tussentijds opslaan',
    'Save checklist as final': 'Opslaan',
    'Add form': 'Formulier toevoegen',
    'Are you sure you want to delete this form?': 'Weet je zeker dat je dit formulier wilt verwijderen?',
    'You cannot revert this action.': 'Je kan deze actie niet terugdraaien.',
    'Select an option': 'Selecteer een optie',
    'injection-ml': 'Injectie ML',
    'injection-cc': 'Injectie CC',
    'ampoule-iv': 'Ampul IV',
    'ampoule-im': 'Ampul IM',
    'ampoule-subcutaneous': 'Ampul Subcutaan',
    'antibiotics-iv': 'Antibiotica IV',
    'pill': 'Pil',
    'infusion-ml': 'Infusie ML',
}
