import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createRouter, createWebHistory } from 'vue-router';
import { install } from 'vue3-recaptcha-v2';
import { createPinia } from 'pinia';
import '~/assets/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'floating-vue/dist/style.css';
import { vMaska } from 'maska';
import * as Sentry from '@sentry/vue';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { createGtm } from '@gtm-support/vue-gtm';
import config from '~/config';
import createLocalizer from '~/i18n';
import nl from '~/i18n/nl';
import withPipeline from '~/lib/Router';
import middleware from '~/middleware';
import routes from '~/routes';
import { GraphQLClient } from '~/services/graphql';
import validation from '~/validation';
import useToast from '~/stores/toast';
import { isDev, isLocal, isTesting } from '~/services/env';

import type { Component } from 'vue';
import type { Router } from 'vue-router';

export async function appInstance(rootComponent: Component, rootContainer: string, withRoutes = true): Promise<void> {
  const app = createApp(rootComponent);

  const pinia = createPinia()
    .use(piniaPluginPersistedstate);

  const router = withRoutes ? withPipeline(createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior: (to, from) => {
      if (to.path === from.path) {
        return undefined;
      }

      return {
        top: 0,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }), middleware) : (): void => {};

  const i18n = await createLocalizer(config.locale, config.fallbackLocale, {
    nl,
  });

  const gtm = createGtm({
    id: 'G-FCXHK3DR14',
    vueRouter: router,
    trackViewEventProperty: 'page-load',
  });

  app.config.errorHandler = (error, instance, info): void => {
    useToast('error', {
      header: 'Something went wrong',
      // eslint-disable-next-line no-nested-ternary
      body: isDev()
        ? ((error instanceof Error) ? `[${info}] ${error.message}` : info)
        : 'Unfortunately an unidentified error has occurred.',
    }, null);

    console.error(error);
  };

  window.addEventListener('unhandledrejection', event => {
    const error = event.reason;

    useToast('error', {
      header: 'Something went wrong',
      // eslint-disable-next-line no-nested-ternary
      body: isDev()
        ? ((error instanceof Error) ? `${error.message}` : 'Unhandled promise rejection')
        : 'Unfortunately an unidentified error has occurred.',
    }, null);

    console.error(error);
    Sentry.captureException(error);

    return true;
  });

  if (withRoutes && !isLocal() && !isTesting()) {
    Sentry.init({
      app,
      environment: import.meta.env.VITE_SERVER_ENVIRONMENT ?? 'local',
      dsn: isDev() ? undefined : config.sentryDsn,
      tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router as Router),
        }),
        new Sentry.Replay(),
      ],
      beforeBreadcrumb: excludeGraphQLFetch,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });
  }

  app.use(pinia)
    .use(router)
    .use(i18n)
    .use(validation)
    .use(install, { sitekey: import.meta.env.VITE_RECAPTCHA_KEY })
    .use(gtm)
    .provide(DefaultApolloClient, GraphQLClient)
    .directive('maska', vMaska);

  app.mount(rootContainer);
}
