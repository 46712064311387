import { ref } from 'vue';
import { defineStore } from 'pinia';

const useSidebar = defineStore('sidebar', () => {
  const collapsed = ref<boolean>(false);

  function toggle(): boolean {
    collapsed.value = !collapsed.value;

    return collapsed.value;
  }

  function open(): void {
    collapsed.value = false;
  }

  function close(): void {
    collapsed.value = true;
  }

  return {
    collapsed,
    toggle,
    open,
    close,
  };
}, {
  persist: {
    storage: {
      getItem: (key: string) => JSON.parse(localStorage.getItem(key) ?? '{}'),
      setItem: (key: string, value: string) => localStorage.setItem(key, JSON.stringify(value)),
    },
  },
});

export default useSidebar;
