<script lang="ts" setup>
  import {
    Dialog as HeadlessUiDialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import {
    faArrowRightFromBracket,
    faBars,
    faChevronLeft,
    faChevronRight,
    faXmark,
  } from '@fortawesome/pro-regular-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import AvatarPlaceholder from '../misc/AvatarPlaceholder.vue';
  import Navigation from '../navigation/Navigation.vue';
  import Logo from '~/components/misc/Logo.vue';
  import useSidebar from '~/stores/sidebar';
  import useAuthStore from '~/stores/auth';
  import logo from '~/assets/logo.svg';

  import type { NavigationMenu } from '~/typings/types';

  withDefaults(defineProps<{
    modelValue?: boolean;
    navigationItems: NavigationMenu;
  }>(), {
    modelValue: false,
    collapsed: true,
  });

  const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void;
    (event: 'update:collapsed', value: boolean): void;
  }>();

  const auth = useAuthStore();
  const sidebar = useSidebar();
</script>

<template>
  <div>
    <TransitionRoot
      :show="modelValue"
      as="template"
    >
      <HeadlessUiDialog
        as="div"
        class="relative z-40 md:hidden"
        @close="() => emit('update:modelValue', false)"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600/75"></div>
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pb-4 pt-5">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full
                      focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="() => emit('update:modelValue', false)"
                  >
                    <span class="sr-only">Close sidebar</span>

                    <FontAwesomeIcon
                      :icon="faXmark"
                      size="lg"
                      aria-hidden="true"
                      class="text-white"
                      fixed-width
                    />
                  </button>
                </div>
              </TransitionChild>

              <div class="flex shrink-0 items-center px-4">
                <img
                  :src="logo"
                  class="h-8 w-auto"
                  alt="Stichting Ambulance Wens logo"
                >
              </div>

              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <Navigation :items="navigationItems" />
              </div>
            </DialogPanel>
          </TransitionChild>

          <div
            class="w-14 shrink-0"
            aria-hidden="true"
          ></div>
        </div>
      </HeadlessUiDialog>
    </TransitionRoot>

    <div class="fixed flex h-screen w-screen flex-col md:flex-row">
      <div
        :class="{
          'md:w-52 xl:w-64': !sidebar.collapsed,
          'md:w-24': sidebar.collapsed,
        }"
        class="relative hidden rounded-tr-3xl border-r border-gray-700 bg-gray-900 p-6
          transition-all duration-300 md:fixed md:inset-y-0 md:flex md:flex-col"
      >
        <button
          type="button"
          class="absolute -right-2 z-10 flex h-6 w-6 items-center
            justify-center rounded-lg border border-gray-700"
          style="background-color: #161A23;"
          @click="() => sidebar.toggle()"
        >
          <FontAwesomeIcon
            :icon="sidebar.collapsed ? faChevronRight : faChevronLeft"
            class="text-white"
            fixed-width
          />
        </button>

        <div class="relative flex min-h-0 flex-1 flex-col">
          <div
            class="flex h-fit max-h-[142px] shrink-0 items-center
              overflow-hidden border-b border-gray-700 bg-gray-900 pb-6"
          >
            <Logo :show-text="!sidebar.collapsed" />
          </div>

          <div class="-mx-6 flex h-full flex-1 flex-col overflow-y-auto overflow-x-hidden py-6">
            <Navigation
              :items="navigationItems"
              :collapsed="sidebar.collapsed"
            />
          </div>
        </div>

        <div class="flex flex-col items-center gap-4">
          <button
            v-if="sidebar.collapsed"
            :title="$t('Sign out')"
            class="pointer-events-auto flex w-fit rounded-md p-2 text-white outline-none hover:bg-gray-700"
            data-testid="logout-btn"
            type="button"
            @click.prevent="() => auth.logout()"
          >
            <FontAwesomeIcon
              :icon="faArrowRightFromBracket"
              size="lg"
              aria-hidden="true"
              fixed-width
            />
          </button>

          <RouterLink
            :class="[sidebar.collapsed ? 'justify-center' : 'justify-between']"
            to="/account/profile"
            class="flex w-full items-center gap-2 overflow-hidden bg-gray-900"
          >
            <template v-if="auth.user?.avatar_url">
              <div
                v-if="auth.user.avatar_url"
                :style="{ backgroundImage: `url(${auth.user.avatar_url})` }"
                class="h-8 w-8 rounded-full bg-cover bg-center bg-no-repeat"
              >
              </div>
            </template>

            <template v-else>
              <AvatarPlaceholder
                :name="auth.user?.full_name ?? ''"
                class="shrink-0"
              />
            </template>

            <div
              v-show="!sidebar.collapsed"
              class="flex flex-1 shrink flex-col overflow-hidden"
            >
              <span class="truncate text-sm font-semibold text-white">{{ auth.user?.full_name }}</span>

              <span class="truncate text-xs italic text-white">{{ $t('Show profile') }}</span>
            </div>

            <button
              v-show="!sidebar.collapsed"
              :title="$t('Sign out')"
              class="pointer-events-auto relative shrink-0 rounded-md text-white outline-none"
              data-testid="logout-btn"
              type="button"
              @click.prevent="() => auth.logout()"
            >
              <FontAwesomeIcon
                :icon="faArrowRightFromBracket"
                size="lg"
                aria-hidden="true"
                fixed-width
              />
            </button>
          </RouterLink>
        </div>
      </div>

      <div class="sticky top-0 z-10 flex h-16 shrink-0 bg-white shadow md:hidden">
        <button
          type="button"
          class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none
            focus:ring-2 focus:ring-inset focus:ring-teal-500 md:hidden"
          @click="() => emit('update:modelValue', true)"
        >
          <span class="sr-only">Open sidebar</span>

          <FontAwesomeIcon
            :icon="faBars"
            size="lg"
            aria-hidden="true"
            fixed-width
          />
        </button>

        <div class="flex flex-1 justify-between px-4">
          <div></div>

          <div class="ml-4 flex items-center md:ml-6">
            <Menu
              as="div"
              class="relative ml-3"
            >
              <div>
                <MenuButton
                  class="flex max-w-xs items-center rounded-full bg-white text-sm
                    focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Open user menu</span>

                  <AvatarPlaceholder :name="auth.user?.full_name ?? ''" />
                </MenuButton>
              </div>

              <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md
                    bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <RouterLink
                      :class="[active ? 'bg-gray-100' : '']"
                      to="/account/profile"
                      class="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                    >
                      {{ $t('Show profile') }}
                    </RouterLink>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <a
                      :class="{ 'bg-gray-100': active }"
                      href="#"
                      class="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                      data-testid="logout-btn"
                      @click="() => auth.logout()"
                    >
                      {{ $t('Sign out') }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main
        :class="{
          'md:ml-52 xl:ml-64': !sidebar.collapsed,
          'md:ml-24': sidebar.collapsed,
        }"
        class="flex-1 overflow-auto p-4 transition-all duration-300 md:p-6 xl:p-8"
      >
        <slot></slot>
      </main>
    </div>
  </div>
</template>
