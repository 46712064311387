/* eslint-disable no-underscore-dangle, unicorn/prevent-abbreviations, @typescript-eslint/naming-convention */
import { isAnyFunction } from '~/helpers/function';

// Should match .env.example
const expected = {
  BASE_URL: String,
  DEV: Boolean,
  MODE: String,
  PROD: Boolean,
  SSR: Boolean,
  VITE_API_URL: String,
  VITE_GRAPHQL_URL: String,
  VITE_APP_URL: String,
  VITE_PUSHER_HOST: String,
  VITE_PUSHER_KEY: String,
  VITE_PUSHER_CLUSTER: String,
  VITE_SERVER_ENVIRONMENT: ['local', 'test', 'testing', 'release', 'production'],
  VITE_RECAPTCHA_KEY: String,
  VITE_GOOGLE_MAPS_API_KEY: String,
  CYPRESS_BASE_URL: String,
  VITE_EXPENSES_ENABLED: String,
  VITE_MILEAGE_ENABLED: String,
  VITE_CHECKLISTS_ENABLED: String,
} as const;

const ignore = [
  'VITE_APP_DEV_SERVER_PORT',
  'VITE_APP_DEV_WS_HMR',
];

type ExpectedEnvironment = typeof expected;

type EnvironmentVariable = keyof ExpectedEnvironment;

type _EnvironmentValue<K extends EnvironmentVariable> = ExpectedEnvironment[K];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EnvironmentValue<K extends EnvironmentVariable> = _EnvironmentValue<K> extends (...arguments_: any[]) => any
    ? ReturnType<_EnvironmentValue<K>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    : _EnvironmentValue<K> extends readonly any[]
        ? _EnvironmentValue<K>[number]
        : 'UNHANDLED ENV VARIABLE TYPE'

export type Env = {
    [K in EnvironmentVariable]: EnvironmentValue<K>
}

const actual = import.meta.env;
const _isDevelopment = import.meta.env.DEV;
const _isTest = import.meta.env.VITEST;

const _environment = {} as Env;

for (const [key, value] of Object.entries(actual)) {
  if (ignore.includes(key)) {
    // eslint-disable-next-line no-continue
    continue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let environmentValue: any;

  if (key in expected) {
    const expectedValue = expected[key as keyof Env];

    if (isAnyFunction(expectedValue)) {
      environmentValue = expectedValue(value);
    } else if (Array.isArray(expectedValue) && expectedValue.includes(value)) {
      environmentValue = value;
    } else {
      if (_isDevelopment) {
        console.error(`Unexpected env var type for ${key}:`, value, '\n', 'Expected:', expectedValue);
      }

      environmentValue = value;
    }
  } else {
    if (_isDevelopment && !_isTest) {
      console.warn(`Unexpected env var: ${key}`);
    }

    environmentValue = value;
  }

  Reflect.set(_environment, key, environmentValue);
}

export default function environment<K extends EnvironmentVariable>(key: K, fallback?: Env[K]): Env[K] {
  const value = _environment[key] || fallback;

  if (_isDevelopment && (value === '' || value === undefined || value === null)) {
    console.error(`Env var ${key} is not defined`);
  }

  return value as Env[K];
}

export function featureFlag<K extends EnvironmentVariable>(key: K, fallback?: boolean): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return environment(key, fallback ? 'true' : 'false') === 'true';
}

export function isDev(): boolean {
  return environment('DEV', false);
}

export function isLocal(): boolean {
  return environment('VITE_SERVER_ENVIRONMENT', 'local') === 'local';
}

export function isTesting(): boolean {
  return !!(environment('VITE_SERVER_ENVIRONMENT', 'testing') === 'testing' || _isTest);
}
