import { PermissionName } from '~/graphql/graphql';

import type { RouteRecordRaw } from 'vue-router';

const documentRoutes: RouteRecordRaw[] = [
  {
    name: 'document.overview',
    path: '/documents',
    component: async () => import('~/views/document/Overview.vue'),
    meta: {
      middleware: {
        before: ['auth', 'can'],
      },
      can: [PermissionName.DocumentsViewAll],
    },
  },
];

export default documentRoutes;
