<script lang="ts" setup>
  /* eslint-disable @programic/vue-no-multiple-template-root */
  import { useRoute } from 'vue-router';
  import { computed, onMounted } from 'vue';
  import ToastContainer from '~/components/toasts/ToastContainer.vue';
  import useNotifications from '~/stores/notifications';
  import LoadingScreen from './views/LoadingScreen.vue';
  import AppLayoutVue from './views/AppLayout.vue';
  import useAuthStore from './stores/auth';

  const authStore = useAuthStore();
  const route = useRoute();

  const authenticated = computed(() => {
    return authStore.authenticated;
  });

  onMounted(() => {
    useNotifications();
  });

  function shouldUseAppLayout(): boolean {
    return !route.meta.customLayout;
  }
</script>

<template>
  <RouterView v-slot="{ Component: RouteComponent }">
    <Suspense>
      <template #default>
        <template v-if="authenticated && shouldUseAppLayout()">
          <AppLayoutVue>
            <Component :is="RouteComponent" />
          </AppLayoutVue>
        </template>

        <template v-else>
          <Component :is="RouteComponent" />
        </template>
      </template>

      <template #fallback>
        <template v-if="authenticated && shouldUseAppLayout()">
          <AppLayoutVue>
            <LoadingScreen />
          </AppLayoutVue>
        </template>

        <template v-else>
          <LoadingScreen />
        </template>
      </template>
    </Suspense>
  </RouterView>

  <ToastContainer />
</template>
