<script lang="ts" setup>
  import {
    faCalendar,
    faCalendarRange,
    faCode,
    faCoins,
    faFileInvoiceDollar,
    faFiles,
    faGift,
    faTruckMedical,
    faUsers,
  } from '@fortawesome/pro-regular-svg-icons';
  import { computed, ref } from 'vue';
  import { PermissionName, RoleName } from '~/graphql/graphql';
  import NotificationPanel from '~/components/notifications/NotificationPanel.vue';
  import Sidebar from '~/components/sidebar/Sidebar.vue';
  import env, { featureFlag } from '~/services/env';
  import useAuthStore from '~/stores/auth';

  import type { NavigationMenu } from '~/typings/types';

  const auth = useAuthStore();
  const sidebarOpen = ref(false);
  const isDevelopment = ['local', 'test'].includes(env('VITE_SERVER_ENVIRONMENT'));

  const navigation = computed<NavigationMenu>(() => [
    {
      name: 'Wishes',
      items: [
        {
          name: 'Wishes',
          route: '/wish',
          icon: faGift,
        },
        {
          name: 'Wish planning',
          route: '/wish-planning',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faCalendarRange,
        },
        {
          name: 'Vehicle planning',
          route: '/vehicle-planning',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faCalendar,
        },
        {
          name: 'Availability',
          route: '/availability',
          visible: auth.hasRole(RoleName.Driver, RoleName.Nurse),
          icon: faCalendar,
        },
      ],
    },
    ...(
      featureFlag('VITE_EXPENSES_ENABLED') ? [{
        name: 'Financial',
        items: [
          {
            name: auth.hasRole(RoleName.Driver, RoleName.Nurse) ? 'Declaration' : 'Expenses',
            route: '/expenses',
            icon: faCoins,
            visible: auth.hasPermission(PermissionName.ExpensesViewAll),
          },
        ],
      }] : []
    ),
    {
      name: 'Volunteers',
      items: [
        {
          name: 'Availability',
          route: '/volunteer-availability',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faCalendar,
        },
      ],
    },
    {
      name: 'Management',
      items: [
        {
          name: 'Users',
          route: '/users',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faUsers,
        },
        {
          name: 'Vehicles',
          route: '/vehicles',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faTruckMedical,
        },
        {
          name: 'Files',
          route: '/documents',
          visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
          icon: faFiles,
        },
        {
          route: '/exact',
          name: 'Exact Online',
          visible: auth.hasPermission(PermissionName.ManageExact),
          icon: faFileInvoiceDollar,
        },
      ],
    },
    ...(isDevelopment ? [{
      name: 'Development',
      items: [
        {
          name: 'Components',
          route: '/components',
          visible: auth.hasRole(RoleName.Admin),
          icon: faCode,
        },
      ],
    }] : []),
  ]);
</script>

<template>
  <div>
    <NotificationPanel />

    <Sidebar
      v-model="sidebarOpen"
      :navigation-items="navigation"
    >
      <slot></slot>
    </Sidebar>
  </div>
</template>
