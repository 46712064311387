import baseRules from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import genericRules from '~/validation/rules/generic';
import ibanRules from '~/validation/rules/iban';
import bigRules from '~/validation/rules/big';

import type { App, Plugin } from 'vue';
import type { FieldValidationMetaInfo } from '@vee-validate/i18n';

type ValidationRuleCallbackReturn = boolean | string | Promise<boolean | string>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationRuleFunction<TValue = unknown, TParameters = unknown[] | Record<string, unknown> | any> =
  (value: TValue, params: TParameters, context: FieldValidationMetaInfo) => ValidationRuleCallbackReturn;

export const rules: Record<string, ValidationRuleFunction> = {
  ...baseRules,
  ...genericRules,
  ...ibanRules,
  ...bigRules,
};

const validation: Plugin = {
  install: <T>(app: App<T>): App<T> => {
    const translate = app.config.globalProperties.$t;

    Object.keys(rules).forEach(rule => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defineRule(rule, rules[rule]);
    });

    configure({
      generateMessage: context => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params: Record<string, any> = {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(context.rule?.params ?? []).forEach((parameter: any, index) => {
          if (!parameter) {
            return;
          }

          params[`${context.rule?.name}_${index}`] = parameter;
        });

        return translate(`validationErrors.${context.rule?.name}`, {
          field: translate(context.field),
          value: context.value,
          ...params,
        });
      },
    });

    return app;
  },
};

export default validation;
