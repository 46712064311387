import Authenticated from './Authenticated';
import EnsureEmailIsVerified from './EnsureEmailIsVerified';
import Guest from './Guest';
import Authorized from './Authorized';

const middleware = {
  auth: Authenticated,
  guest: Guest,
  verified: EnsureEmailIsVerified,
  can: Authorized,
} as const;

export default middleware;
