import { ApolloClient, ApolloLink, InMemoryCache, ApolloError } from '@apollo/client/core';
import { withDefaults } from 'apollo-link-sentry/lib/options';
import { provideApolloClient } from '@vue/apollo-composable';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { captureException } from '@sentry/vue';
import { watch } from 'vue';
// eslint-disable-next-line import/extensions
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import PusherLink from '~/services/graphql-pusher';
import useAuthStore from '~/stores/auth';
import useToast, { hasActiveToasts } from '~/stores/toast';
import { getRouter } from '~/lib/Router';
import environment from './env';

import type { UseQueryReturn, UseMutationReturn } from '@vue/apollo-composable';
import type { ApolloQueryResult, OperationVariables } from '@apollo/client/core';

const cache = new InMemoryCache();
const pusherLink = new PusherLink();

const httpLink = createUploadLink({
  uri: environment('VITE_GRAPHQL_URL', '/graphql'),
});

const authLink = setContext((request, { headers }) => {
  const auth = useAuthStore();

  return {
    headers: {
      ...headers,
      Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : undefined,
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: 1000,
    jitter: true,
  },
  attempts: {
    max: 3,
  },
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    console.error(networkError);
  }

  console.error(operation);

  if (environment('DEV', false)) {
    setTimeout(() => {
      if (!hasActiveToasts()) {
        useToast('error', {
          header: 'GraphQL error',
          body: 'There was a problem with a GraphQL request',
        }, null);
      }
    }, 10); // Minor delay to prevent duplicate toasts in case in manual error handling
  }

  if (!graphQLErrors) {
    return;
  }

  for (const error of graphQLErrors) {
    const auth = useAuthStore();
    const context = operation.getContext();

    switch (error.extensions?.category) {
    case 'authentication':
      if (!auth.refreshToken || context.headers.RefreshAttempted) {
        return;
      }

      operation.setContext(async () => {
        const success = await auth.refresh();

        if (!success) {
          return {
            headers: context.headers,
          };
        }

        return {
          headers: {
            ...context.headers,
            RefreshAttempted: true,
            Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : undefined,
          },
        };
      });

      // eslint-disable-next-line consistent-return
      return forward(operation);
    case 'authorization':
      captureException(error);
      getRouter().push({ name: 'unauthorized' });

      return;
    default:
      captureException(error);
      break;
    }
  }
});

const sentryLink = new SentryLink(withDefaults({
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeFetchResult: true,
    includeError: true,
    includeCache: true,
  },
}));

const client = new ApolloClient({
  uri: environment('VITE_GRAPHQL_URL', '/graphql'),
  cache,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: ApolloLink.from([authLink, sentryLink, errorLink, retryLink, pusherLink, httpLink as any]),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

const useQueryPromise = async <R, V extends OperationVariables>(
  query: UseQueryReturn<R, V>,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rejectGraphQLErrors = true,
): Promise<ApolloQueryResult<R>> => new Promise((resolve, reject) => {
  query.onError((error: ApolloError) => reject(error));
  query.onResult((result: ApolloQueryResult<R>) => {
    if (rejectGraphQLErrors && result.errors) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      reject(new ApolloError({ graphQLErrors: result.errors }));
    }

    return resolve(result);
  });
});

const useFulfilled = async <R, V extends OperationVariables>(
  operation: (UseQueryReturn<R, V> | UseMutationReturn<R, V>),
): Promise<void> => {
  return new Promise(resolve => {
    watch(
      () => operation.loading.value,
      loading => !loading && resolve(),
      { immediate: true },
    );
  });
};

provideApolloClient(client);

export { client as GraphQLClient, useQueryPromise, useFulfilled };
