<script lang="ts" setup>
  import { computed } from 'vue';
  import NavigationItem from './NavigationItem.vue';

  import type { NavigationMenuGroup } from '~/typings/types';

  const props = defineProps<{
    item: NavigationMenuGroup;
    collapsed?: boolean;
  }>();

  const navigationItems = computed(() => {
    return props.item.items.filter(item => item.visible === undefined || item.visible);
  });
</script>

<template>
  <div
    v-if="navigationItems.length > 0"
    :class="{ 'items-center': collapsed }"
    class="mb-6 flex flex-col overflow-visible text-white last:mb-0"
    data-testid="nav-group"
  >
    <h4
      :class="{ 'text-2xs': collapsed }"
      class="mb-2 text-gray-300 transition-all"
    >
      {{ $t(props.item.name) }}
    </h4>

    <NavigationItem
      v-for="navigationItem in navigationItems"
      :key="navigationItem.name"
      :item="navigationItem"
      :collapsed="collapsed"
    />
  </div>
</template>
