// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: unknown): value is object | any[] {
  return typeof value === 'object' && value !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isKeyValueObject(value: unknown): value is Record<string, any> {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function getObjectProperty(object: object, path: string): unknown {
  // eslint-disable-next-line unicorn/no-array-reduce, @typescript-eslint/no-explicit-any
  return path.split('.').reduce((accumulator, part) => (accumulator as any)?.[part], object);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterNullishValues(object: Record<string, any>): Record<string, any> {
  Object.keys(object).forEach(key => {
    if (object[key] == null) {
      delete object[key];
    }
  });

  return object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyObject(object: Record<string, any>): boolean {
  return Object.keys(object).length === 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDirty<M extends object = Record<any, any>, O extends object = Record<any, any>>(
  modified: M,
  original: O,
  checkDeep = false,
): Partial<M> {
  const dirty: Partial<M> = {};

  for (const key of Object.keys(modified) as Array<keyof M>) {
    const modifiedValue = Object.getOwnPropertyDescriptor(modified, key)?.value;
    const originalValue = Object.getOwnPropertyDescriptor(original, key)?.value;

    if (checkDeep && isObject(modifiedValue) && isObject(originalValue)) {
      if (!isEmptyObject(getDirty(modifiedValue, originalValue, true))) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dirty[key] = modifiedValue as any;
      }
    } else if ((modifiedValue || originalValue) && modifiedValue !== originalValue) {
      dirty[key] = modifiedValue;
    }
  }

  return dirty;
}

export const extend = Object.assign;
