import { useI18n } from 'vue-i18n';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { getRouter } from '~/lib/Router';
import useAuthStore from '~/stores/auth';
import useToast from '~/stores/toast';
import {
  NotificationCreatedDocument,
  NotificationDeletedDocument,
  NotificationType,
  NotificationUpdatedDocument,
  useNotificationsLazyQuery,
  useSetNotificationDeletedMutation,
  useSetNotificationReadMutation,
  useSetNotificationUnreadMutation,
} from '~/graphql/graphql';

import type {
  Notification as GqlNotification,
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables,
  NotificationDeletedSubscription,
  NotificationDeletedSubscriptionVariables,
  NotificationUpdatedSubscription,
  NotificationUpdatedSubscriptionVariables,
} from '~/graphql/graphql';

export interface Notification {
  uuid: string;
  header: string;
  body: string;
  persistent: boolean;
  read: boolean;
  original: GqlNotification;
  action?: {
    label: string;
    handler: (event: MouseEvent, toastId?: symbol) => void;
  };
}

const useNotifications = defineStore('notifications', () => {
  const { t, d } = useI18n();
  const auth = useAuthStore();
  const notifications = ref(new Map<string, Notification>());
  const notificationPanelOpen = ref(false);
  const query = useNotificationsLazyQuery({ fetchPolicy: 'network-only' });

  watch(
    () => auth.authenticated,
    async (value, oldValue) => {
      if (value && !oldValue) await mount();

      if (!value && oldValue) unMount();
    },
    { immediate: true },
  );

  function openNotificationPanel(): void {
    notificationPanelOpen.value = true;
  }

  function closeNotificationPanel(): void {
    notificationPanelOpen.value = false;
  }

  function makeNotification(gqlNotification: GqlNotification): Notification | undefined {
    const notification: Notification = {
      uuid: gqlNotification.uuid,
      header: '',
      body: '',
      persistent: false,
      read: gqlNotification.read_at !== null,
      original: gqlNotification,
    };

    let data: Record<string, unknown> = {};

    try {
      data = JSON.parse(gqlNotification.data);
    } catch {
      data = {};
    }

    // eslint-disable-next-line default-case
    switch (gqlNotification.type) {
    case NotificationType.NewWish:
      notification.header = t('New wish');
      notification.body = t(
        '{contact} has requested a new wish for {date}.',
        {
          contact: data?.contact,
          date: d(new Date(data?.date), 'shortDate'),
        },
      );
      notification.action = {
        label: t('View the wish request'),
        handler: async (): Promise<void> => {
          closeNotificationPanel();
          await getRouter().push({ name: 'wish', params: { id: data?.wish_id } });
        },
      };
      break;
    default:
      return undefined;
    }

    return notification;
  }

  function handleNewNotification(notification: Notification): void {
    if (notification.original.type && !notification.read) {
      useToast('info', {
        header: notification.header,
        body: notification.body,
        action: notification.action,
      }, notification.persistent ? 0 : 5000);
    }
  }

  function unMount(): void {
    query.stop();
    notifications.value.clear();
  }

  async function mount(): Promise<void> {
    unMount();

    query.subscribeToMore<NotificationCreatedSubscriptionVariables, NotificationCreatedSubscription>({
      document: NotificationCreatedDocument,
      updateQuery: (previousResult, { subscriptionData }) => {
        const temporary = [...(previousResult.notifications ?? [])];

        if (subscriptionData.data.notificationCreated) {
          const notification = makeNotification(subscriptionData.data.notificationCreated);

          if (notification) {
            notifications.value.set(notification.uuid, notification);
            handleNewNotification(notification);
          }
        }

        return {
          notifications: temporary,
        };
      },
    });
    query.subscribeToMore<NotificationUpdatedSubscriptionVariables, NotificationUpdatedSubscription>({
      document: NotificationUpdatedDocument,
      updateQuery: (previousResult, { subscriptionData }) => {
        const temporary = [...(previousResult.notifications ?? [])];

        if (subscriptionData.data.notificationUpdated) {
          const notification = makeNotification(subscriptionData.data.notificationUpdated);

          if (notification) notifications.value.set(notification.uuid, notification);
        }

        return {
          notifications: temporary,
        };
      },
    });
    query.subscribeToMore<NotificationDeletedSubscriptionVariables, NotificationDeletedSubscription>({
      document: NotificationDeletedDocument,
      updateQuery: (previousResult, { subscriptionData }) => {
        const temporary = [...(previousResult.notifications ?? [])];

        if (subscriptionData.data.notificationDeleted) {
          notifications.value.delete(subscriptionData.data.notificationDeleted.uuid);
        }

        return {
          notifications: temporary,
        };
      },
    });
    query.onResult(({ data }) => {
      data.notifications.forEach(item => {
        const notification = makeNotification(item);

        if (notification) notifications.value.set(notification.uuid, notification);
      });
    });
    query.start();
    query.load();
  }

  async function setRead(uuid: string): Promise<void> {
    await useSetNotificationReadMutation().mutate({ input: { uuid } });
  }

  async function setUnRead(uuid: string): Promise<void> {
    await useSetNotificationUnreadMutation().mutate({ input: { uuid } });
  }

  async function setDeleted(uuid: string): Promise<void> {
    await useSetNotificationDeletedMutation().mutate({ input: { uuid } });
  }

  return {
    setRead,
    setUnRead,
    setDeleted,
    openNotificationPanel,
    closeNotificationPanel,

    notificationsRaw: notifications,
    notifications: computed<Notification[]>(
      () => [...notifications.value.values()].sort((notificationA, notificationB) => {
        return Number(notificationA.read) - Number(notificationB.read) || (
          new Date(notificationB.original.created_at ?? '')
        ).valueOf() - (new Date(notificationA.original.created_at ?? '')).valueOf();
      }),
    ),
    notificationPanelOpen,
    unreadCount: computed(
      () => [...notifications.value.values()].filter(item => !item.read).length,
    ),
  };
}, {
  persist: false,
});

export default useNotifications;
