/* eslint-disable @typescript-eslint/naming-convention */

// See: https://nl.iban.com/structuur
import type { ValidationRuleFunction } from '~/validation';

const CODE_LENGTHS: Record<string, number> = {
  AL: 28, // Albanie
  AD: 24, // Andorra
  AZ: 28, // Azerbeidjan
  BH: 22, // Bahrein
  BE: 16, // Belgie
  BA: 20, // Bosnie en Herzegovina
  BR: 29, // Brazilie
  BG: 22, // Bulgarije
  CR: 22, // Costa Rica
  HR: 21, // Croatie
  CY: 28, // Cyprus
  DK: 18, // Denemarken
  DO: 28, // Dominicaanse Republiek
  DE: 22, // Duitsland
  EG: 29, // Egypte
  SV: 28, // El Salvador
  EE: 20, // Estland
  FO: 18, // Faroe Eilanden
  FI: 18, // Finland
  FR: 27, // Frankrijk
  GE: 22, // Georgie
  GI: 23, // Gibraltar
  GR: 27, // Griekenland
  GL: 18, // Groenland
  GT: 28, // Guatemala
  HU: 28, // Hongarije
  IE: 22, // Ierland
  IS: 26, // IJsland
  IQ: 23, // Irak
  IL: 23, // Israel
  IT: 27, // Italie
  JO: 30, // Jordanie
  KZ: 20, // Kazakhstan
  XK: 20, // Kosovo
  KW: 30, // Kuweit
  LV: 21, // Letland
  LB: 28, // Libanon
  LI: 21, // Liechtenstein
  LT: 20, // Litouwen
  LU: 20, // Luxemburg
  VG: 24, // Maagdeneilanden, Britse
  MK: 19, // Macedonie
  MT: 31, // Malta
  MR: 27, // Mauritanie
  MU: 30, // Mauritius
  MD: 24, // Moldavie
  MC: 27, // Monaco
  ME: 22, // Montenegro
  NL: 18, // Nederland
  NO: 15, // Norwegen
  AT: 20, // Oostenrijk
  PK: 24, // Pakistan
  PS: 29, // Palestina
  PL: 28, // Polen
  PT: 25, // Portugal
  QA: 29, // Qatar
  RO: 24, // Roemenie
  LC: 32, // Saint Lucia
  SM: 27, // San Marino
  ST: 25, // Sao Tome en Principe
  SA: 24, // Saudi Arabie
  RS: 22, // Servie
  SC: 31, // Seychellen
  SI: 19, // Slovenie
  SK: 24, // Slowakije
  ES: 24, // Spanje
  TL: 23, // Timor-Leste
  CZ: 24, // Tjechie
  TN: 24, // Tunisie
  TR: 26, // Turkije
  UA: 29, // Ukraine
  GB: 22, // Verenigd Koninkrijk
  AE: 23, // Verenigde Arabische Emiraten
  BY: 28, // Wit Rusland
  SE: 24, // Zweden
  CH: 21, // Zwitserland
};

const module97 = (value: string): number => {
  let checksum = Number(value.slice(0, 2));

  for (let offset = 2; offset < value.length; offset += 7) {
    const fragment = String(checksum) + value.slice(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
};

const ibanValid = (value: unknown): boolean => {
  if (typeof value !== 'string') {
    return false;
  }

  const iban = value.toUpperCase().replace(/\s/g, '');

  if (iban.trim().length === 0) {
    return false;
  }

  const code = iban.match(/^([A-Z]{2})(\d{2})([\dA-Z]+)$/);

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }

  const digits = (code[3] + code[1] + code[2])
    .replace(/[A-Z]/g, letter => ((letter.codePointAt(0) ?? 0) - 55).toString());

  return module97(digits) === 1;
};

const ibanRules: Record<string, ValidationRuleFunction> = {
  iban_valid: ibanValid,
};

export default ibanRules;
