const validationErrors = {
    'Wish requires a date.': 'Wens vereist een datum.',
    'Wish date cannot be in the past.': 'Wens datum kan niet in het verleden zijn.',
    'Wish end date cannot be before the start date.': 'Wens eind datum kan niet voor de begin datum zijn.',
    'Wish duration cannot be longer than 4 days.': 'Wens duur kan niet langer dan 4 dagen zijn.',
    'Wish requires two volunteers.': 'Wens vereist twee vrijwilligers.',
    'Wish requires both a driver and a nurse.': 'Wens vereist een chauffeur en een verpleegkundige.',
    'Volunteer is not available for the required date(s).': 'Vrijwilliger is niet beschikbaar voor de vereiste datum(s).',
    'Volunteer has already been planned for wish date.': 'Vrijwilliger is al gepland voor deze datum.',
    'Wish requires a vehicle': 'Wens vereist een voertuig.',
    'Vehicle is not available for the required date(s).': 'Voertuig is niet beschikbaar voor de vereiste datum(s).',
    'Vehicle has already been planned on two wishes.': 'Voertuig is al gepland voor twee wensen.',
}

export default {
    'Amblance id is not a valid id.': 'Het ID van het voertuig wordt niet herkend in het systeem.',
    'Wish id is not a valid id.': 'Het ID van de wens wordt niet herkend in het systeem.',
    'The provided dates are invalid': 'De ingevoerde datums zijn niet geldig',
    'The input dates are invalid.': 'De ingevoerde datums zijn niet geldig.',
    'This wish date is invalid.': 'Deze datum is niet geldig.',
    'The provided dates clash with other days for this ambulance.': 'De ingevoerde datums botsen met andere wensen.',
    'Error while saving wish': 'Er ging iets verkeerd tijdens het opslaan',
    'Due to an unforeseen error, the data you entered could not be saved.': 'In verband met een onvoorziene fout hebben we de data helaas niet kunnen opslaan.',
    'Saved!': 'Opgeslagen!',
    'The data for this wish has been successfully saved.': 'De data voor deze wens is met succes opgeslagen.',
    'The data for this reservation has been successfully saved.': 'De gegevens voor deze reservering is met succes opgeslagen.',
    'The data for this reservation has been successfully deleted.': 'De gegevens voor deze reservering zijn succesvol verwijderd.',
    'A wish may take no longer than 4 days.': 'De wensen mag niet meer dan 4 dagen duren.',
    'A wish cannot be planned in the past.': 'Een wens mag niet in het verleden worden ingepland.',
    'GraphQL error': 'GraphQL fout',
    'There was a problem with a GraphQL request': 'Er ging iets mis bij een GraphQL request.',
    'Active filters': 'Actieve filters',
    'Choose a vehicle': 'Kies een voertuig',
    'Planning wish': 'Planning wens',
    Wish: 'Wens',
    'Wish details': 'Wens details',
    Unknown: 'Onbekend',
    'Multiple days': 'Meerdere dagen',
    'The current days clash with other already planned wishes.': 'Deze dagen clashen met andere al ingeplande wensen.',
    'This date clashes with other already planned wishes.': 'Deze dag clasht met andere al ingeplande wensen.',
    Delete: 'Verwijderen',
    Select: 'Selecteer',
    Filter: 'Filteren',
    'Reset filter': 'Reset filter',
    ToCancel: 'Annuleren',
    'Save as draft': 'Tussentijds opslaan',
    'Save as final': 'Planning definitief maken',
    'Name volunteer': 'Naam vrijwilliger',
    Specializations: 'Specialisaties',
    'Starting location': 'Startlocatie',
    Date: 'Datum',
    From: 'Van',
    To: 'Tot',
    'Pickup location': 'Ophaallocatie',
    'Wish location': 'Wenslocatie',
    'Amount of people going on wish': 'Aantal personen die meegaan naast patient',
    'No wish data could be found.': 'Wens kon niet gevonden worden.',
    Residence: 'Woonplaats',
    'Search by name': 'Zoek op naam',
    'Filter by specialization': 'Filter op specialisatie',
    'Filter by residence': 'Zoek op woonplaats',
    'Filter by role': 'Filter op rol',
    Volunteer: 'Vrijwilliger',
    'Show available volunteers': 'Beschikbare vrijwilligers tonen',
    'If this filter is disabled, only volunteers that are NOT available will be shown': 'Als deze filter is uitgeschakeld, '
        + 'worden enkel vrijwilligers die NIET beschikbaar zijn getoond',
    Vehicle: 'Voertuig',
    'The wish': 'De wens',
    'Choose a volunteer from the table': 'Kies een vrijwilliger uit de tabel',
    'Click here to add the departure time': 'Klik hier om de vertrektijd toe te voegen',
    'Click here to add the subject': 'Klik hier om het onderwerp toe te voegen',
    departure_at: 'Vertrektijd',
    arrival_at: 'Aankomsttijd',
    'Show only available volunteers': 'Toon alleen beschikbare vrijwilligers',
    'Show only unavailable volunteers': 'Toon alleen niet-beschikbare vrijwilligers',
    'Search by name or residence': 'Zoek op naam of woonplaats',
    'Reset all': 'Alles resetten',
    'Volunteers': 'Vrijwilligers',
    'Are you sure you want to make this change?': 'Weet je zeker dat je deze wijziging wilt doorvoeren?',
    'First check whether this fits into the schedule and the availability of the linked volunteers.':
        'Controleer eerst of dit past in de planning en de beschikbaarheid van de gekoppelde vrijwilligers.',
    'Planned': 'Ingepland',
    ...validationErrors,
};
