import { PermissionName } from '~/graphql/graphql';

import type { RouteRecordRaw, RouteMeta } from 'vue-router';

const wishMeta: RouteMeta = {
  middleware: {
    before: ['auth', 'can'],
  },
  can: [
    PermissionName.WishView,
  ],
};

const wishRoutes: RouteRecordRaw[] = [
  {
    name: 'wish.overview',
    path: '/wish',
    component: async () => import('~/views/wish/Overview.vue'),
    meta: {
      middleware: {
        before: ['auth', 'can'],
      },
      can: [PermissionName.WishesViewAll],
    },
  },
  {
    name: 'wish.request',
    path: '/wish/request',
    component: async () => import('~/views/wish/Request/Index.vue'),
  },
  {
    name: 'wish',
    path: '/wish/:id',
    component: async () => import('~/views/wish/Detail/Index.vue'),
    meta: wishMeta,
    props: true,
    children: [
      {
        name: 'wish.checklists',
        path: 'checklists',
        redirect: {
          name: 'wish.checklists.overview',
        },
        meta: wishMeta,
        children: [
          {
            name: 'wish.checklists.overview',
            path: 'overview',
            component: async () => import('~/views/wish/Detail/Tabs/Checklists/Tabs/Overview/Overview.vue'),
            meta: wishMeta,
          },
          {
            name: 'wish.checklists.detail',
            path: ':checklistId',
            component: async () => import('~/views/wish/Detail/Tabs/Checklists/Tabs/Detail/Checklist.vue'),
            meta: wishMeta,
            props: true,
          },
        ],
      },
    ],
  },
  {
    name: 'wish.confirm',
    path: '/wish/confirm',
    component: async () => import('~/views/wish/Confirm.vue'),
    meta: {
      customLayout: true,
    },
  },
];

export default wishRoutes;
