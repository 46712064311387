<script lang="ts" setup>
  import { provide } from 'vue';

  const props = withDefaults(defineProps<{
    space?: 'sm'|'md'|'lg'|'xl';
  }>(), {
    space: 'md',
  });

  provide('cardSpace', props.space);
</script>

<template>
  <div class="w-full overflow-hidden rounded-3xl bg-white shadow-card sm:w-auto">
    <div
      :class="{
        'px-4': space === 'sm',
        'px-6': space === 'md',
        'px-8': space === 'lg',
        'px-10': space === 'xl',
      }"
      class="my-6 h-full"
    >
      <slot></slot>
    </div>
  </div>
</template>
